<template>
    <section>
        <div class="footer-container w-full px-10 mobileL:pt-32 pt-24 mobileL:pb-40 pb-4 flex items-center p-10 text-white-1 text-center font-century font-normal">
            <div class="h-4/6 w-full">
                <div class="mobileL:grid grid-cols-5 hidden w-10/12 mx-auto mb-6">
                    <router-link class="font-extrabold text-2xl xl:text-3xl 2xl:text-4xl" to="/laybare">Home</router-link>
                    <router-link class="font-extrabold text-2xl xl:text-3xl 2xl:text-4xl" to="/laybare/branches">Location</router-link>
                    <router-link class="font-extrabold text-2xl xl:text-3xl 2xl:text-4xl" to="/laybare/services">Services</router-link>
                    <router-link class="font-extrabold text-2xl xl:text-3xl 2xl:text-4xl" to="/laybare/products">Products</router-link>
                    <router-link class="font-extrabold text-2xl xl:text-3xl 2xl:text-4xl" to="/app">App</router-link>
                </div>

                <div class="mobileL:grid grid-cols-5 w-10/12 mx-auto mb-8 hidden">
                    <ul>
                        <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                            <router-link class="hover:text-violet-1" to="/laybare#why-laybare" @click.native="scrollToId('why-laybare')">Introduction</router-link>
                        </li>
                        <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                            <router-link class="hover:text-violet-1" to="/laybare#why-laybare" @click.native="scrollToId('why-laybare')">Why LayBare</router-link>
                        </li>
                        <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                            <router-link class="hover:text-violet-1" to="/laybare#testimonials" @click.native="scrollToId('testimonials')">What our clients say about us</router-link>
                        </li>
                    </ul>
                    <ul>
                        <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                            <router-link class="hover:text-violet-1" to="/laybare/branches">Search a map</router-link>
                        </li>
                        <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                            <router-link class="hover:text-violet-1" to="/laybare/branches#map" @click.native="scrollToId('map')">Location map</router-link>
                        </li>
                    </ul>
                    <ul>
                        <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                            <router-link class="hover:text-violet-1" to="/laybare/services">Our Promos</router-link>
                        </li>
                        <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                            <router-link class="hover:text-violet-1" to="/laybare/services#service-we-offer" @click.native="scrollToId('service-we-offer')">Service We Offer</router-link>
                        </li>
                        <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                            <router-link class="hover:text-violet-1" to="/laybare/services#our-packages" @click.native="scrollToId('our-packages')">Our Packages</router-link>
                        </li>
                        <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                            <router-link class="hover:text-violet-1" to="/laybare/services#know-before-you-go" @click.native="scrollToId('know-before-you-go')">Know Before you go</router-link>
                        </li>
                    </ul>
                    <ul>
                        <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                            <router-link class="hover:text-violet-1" to="/laybare/products">Our Promos</router-link>
                        </li>
                        <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                            <p>Customer Feedbacks</p>
                        </li>
                        <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                            <router-link class="hover:text-violet-1" to="/laybare/products#our-products" @click.native="scrollToId('our-products')">Our Products</router-link>
                        </li>
                    </ul>
                    <ul>
                        <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                            <router-link class="hover:text-violet-1" to="/app/download">Laybare is in your device</router-link>
                        </li>
                        <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                            <router-link class="hover:text-violet-1" to="/app/download#user-feedback" @click.native="scrollToId('user-feedback')">User Feedback</router-link>
                        </li>
                        <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                            <router-link class="hover:text-violet-1" to="/app/download#app-benefits" @click.native="scrollToId('app-benefits')">App Benefits</router-link>
                        </li>
                    </ul>
                </div>

                <div class="grid mobileL:grid-cols-9 gap-4 mobileL:gap-0 -mt-20 mobileL:mt-0 mobileL:mb-12 mb-6 w-11/12 mx-auto">
                    <router-link class="mobileL:font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybare/plc-perks">PLC Perks</router-link>
                    <router-link class="mobileL:font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybare/promos">Promos</router-link>
                    <router-link class="mobileL:font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybare/blogs">Blogs</router-link>
                    <router-link class="mobileL:font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybare/about-us">About Us</router-link>
                    <router-link class="mobileL:font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybare/careers">Careers</router-link>
                    <router-link class="mobileL:font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybare/faqs">FAQs</router-link>
                    <router-link class="mobileL:font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybare/legal-terms">Legal Terms</router-link>
                    <router-link class="mobileL:font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybare/contact-us">Contact Us</router-link>
                    <router-link class="mobileL:font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybare/safety-standards">Safety Standards</router-link>
                </div>

                <div class="mobileL:grid mobileL:grid-cols-3 w-full items-center">
                    <div class="bg-white-1 w-full hidden mobileL:block" style="height: 2px;"></div>
                    <div class="grid grid-cols-4 mobileL:w-full w-3/4 m-auto">
                        <a href="https://www.facebook.com/OfficialLayBare/" class="w-10 h-10 md:w-12 md:h-12 xl:w-14 2xl:w-16 xl:h-14 2xl:h-16 rounded-full border-2 flex items-center justify-center mx-auto" target="_blank">
                            <i class="fab fa-facebook-f fa-lg"></i>
                        </a>
                        <a href="https://www.instagram.com/lay_bare/" class="w-10 h-10 md:w-12 md:h-12 xl:w-14 2xl:w-16 xl:h-14 2xl:h-16 rounded-full border-2 flex items-center justify-center mx-auto" target="_blank">
                            <i class="fab fa-instagram fa-lg"></i>
                        </a>
                        <a href="https://twitter.com/laybareph" class="w-10 h-10 md:w-12 md:h-12 xl:w-14 2xl:w-16 xl:h-14 2xl:h-16 rounded-full border-2 flex items-center justify-center mx-auto" target="_blank">
                            <i class="fab fa-twitter fa-lg"></i>
                        </a>
                        <a href="https://www.tiktok.com/@officiallaybare" class="w-10 h-10 md:w-12 md:h-12 xl:w-14 2xl:w-16 xl:h-14 2xl:h-16 rounded-full border-2 flex items-center justify-center mx-auto">
                            <i class="fab fa-tiktok fa-lg"></i>
                        </a>
                    </div>
                    <div class="bg-white-1 w-full hidden mobileL:block" style="height: 2px;"></div>
                </div>

                <div class="footer-brand-logo mt-4 mobileL:mt-6">
                    <img class="w-32 xl:w-40 2xl:w-48 mx-auto" src="../../assets/images/home-page/header/logo2.png" alt="Lay Bare">
                    <div class="text-base xl:text-lg 2x:text-xl">
                        <p>Copyright &copy; {{new Date().getFullYear()}} Lay Bare Waxing Philippines Inc.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
export default {
    methods: {
        scrollToId(id) {
            document.getElementById(id).scrollIntoView();
        },
    }
}
</script>

<style scoped>
.footer-container {
    background: url('../../assets/images/footer-bg.jpg') no-repeat;
    background-size: cover;
}
</style>